<template>
    <OtpForm
        :form="form"
        :submit="submit"
        :channel="channel"
    />
</template>

<script>
import {useForm} from "@inertiajs/vue3";
import OtpForm from "@/Components/OtpForm.vue";
import {setUserData, track} from "@/Helpers/zaraz";

export default {
    components: {OtpForm},
    props: {
        phone: String,
        remember: Boolean,
        additionalData: Object,
        noRedirect: {
            type: Boolean,
            default: false
        },
        channel: String
    },
    data() {
        return {
            form: useForm({
                phone: this.phone,
                remember: this.remember,
                via_sms: false,
                verification_code: '',
                no_redirect: this.noRedirect,
                ...this.additionalData
            })
        }
    },
    methods: {
        submit() {
            this.form.post(this.route('login'), {
                only: ['errors', 'auth'],
                onSuccess: (data) => {
                    this.$emit('authenticated')

                    setUserData(data.props.auth.user)

                    track('login', {method: 'Phone'})
                },
                onError: () => {
                    this.form.reset('verification_code')

                    document.getElementsByClassName('opt-input-one')[0].focus()
                },
            });
        },
    }
}
</script>
